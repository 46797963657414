import { FooterSettings, SupportedLanguage } from "../../shared/models";
import { ListeningPostFooterContentBase } from "./listening-post-footer-content-base";

export class LifeWiseIndividualFooterContent extends ListeningPostFooterContentBase {

  //LifeWise Individual & Family plans

  private documentLink = "https://www.lifewisewa.com/documents/051267.pdf";
  private entityName = "LifeWise Health Plan of Washington";

  pdfUrl(): string {
    return this.documentLink;
  }

  legalEntityName(): string {
    return this.entityName;
  }

  languageSupport(): SupportedLanguage[] {
    return [
      this.spanish(),
      this.chinese(),
      this.vietnamese(),
      this.korean(),
      this.russian(),
      this.tagalog(),
      this.ukrainian(),
      this.khmer(),
      this.japanese(),
      this.amharic(),
      this.oromo(),
      this.arabic(),
      this.punjabi(),
      this.german(),
      this.lao(),
      this.haitian(),
      this.french(),
      this.polish(),
      this.portuguese(),
      this.italian(),
      this.farsi()
    ]
  }
}
