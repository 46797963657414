import { FooterSettings, SupportedLanguage } from "../../shared/models";

export abstract class ListeningPostFooterContentBase {

  //DEFINITIONS
  //https://nwhealthconnection.sharepoint.com/sites/DocAdminProduction/dalibrary/037625.docx

  abstract pdfUrl(): string;

  abstract legalEntityName(): string;

  abstract languageSupport(): SupportedLanguage[];

  GetFooterSettings(): FooterSettings {
    const legalDisclaimer = this.baseLegalDisclaimerHtml()
      .split('{{ReplaceDocumentLink}}').join(this.pdfUrl())
      .split('{{ReplaceLegalEntity}}').join(this.legalEntityName());

    return {
      legalDisclaimerHtml: legalDisclaimer,
      legalEntityName: this.legalEntityName(),
      pdfUrl: this.pdfUrl(),
      languages: this.languageSupport()
    };
  }

  private baseLegalDisclaimerHtml(): string {
    return "{{ReplaceLegalEntity}} complies with applicable <a href=\"{{ReplaceDocumentLink}}\" target=\"_blank\" rel=\"noopener\">Federal and Washington state civil rights laws</a> and does not discriminate on the basis of race, color, national origin, age, disability, sex, gender identity, or sexual orientation. <a href=\"{{ReplaceDocumentLink}}\" target=\"_blank\" rel=\"noopener\">Contact us</a> for free language assistance services and appropriate auxiliary aids and services."
  }

  english(): SupportedLanguage {
    return {
      nativeName: "English",
      name: "English",
      languageCode: "en"
    }
  }

  spanish(): SupportedLanguage {
    return {
      nativeName: "Español",
      name: "Spanish",
      languageCode: "es"
    }
  }

  chinese(): SupportedLanguage {
    return {
      nativeName: "中文",
      name: "Chinese",
      languageCode: "zh"
    }
  }

  vietnamese(): SupportedLanguage {
    return {
      nativeName: "Tiếng Việt",
      name: "Vietnamese",
      languageCode: "vi"
    }
  }

  korean(): SupportedLanguage {
    return {
      nativeName: "한국어",
      name: "Korean",
      languageCode: "ko"
    }
  }

  russian(): SupportedLanguage {
    return {
      nativeName: "Pусский",
      name: "Russian",
      languageCode: "ru"
    }
  }

  tagalog(): SupportedLanguage {
    return {
      nativeName: "Tagalog",
      name: "Tagalog",
      languageCode: "tl"
    }
  }

  ukrainian(): SupportedLanguage {
    return {
      nativeName: "Українська",
      name: "Ukrainian",
      languageCode: "uk"
    }
  }

  khmer(): SupportedLanguage {
    return {
      nativeName: "ភាសាខ្មែរ",
      name: "Khmer",
      languageCode: "km"
    }
  }

  japanese(): SupportedLanguage {
    return {
      nativeName: "日本語",
      name: "Japanese",
      languageCode: "ja"
    }
  }

  amharic(): SupportedLanguage {
    return {
      nativeName: "አማሪኛ",
      name: "Amharic",
      languageCode: "am"
    }
  }

  oromo(): SupportedLanguage {
    return {
      nativeName: "Oromoo",
      name: "Oromo",
      languageCode: "om"
    }
  }

  arabic(): SupportedLanguage {
    return {
      nativeName: "العربي",
      name: "Arabic",
      languageCode: "ar"
    }
  }

  punjabi(): SupportedLanguage {
    return {
      nativeName: "ਪੰਜਾਬੀ",
      name: "Punjabi",
      languageCode: "pa"
    }
  }

  german(): SupportedLanguage {
    return {
      nativeName: "Deutsch",
      name: "German",
      languageCode: "de"
    }
  }

  lao(): SupportedLanguage {
    return {
      nativeName: "ລາວ",
      name: "Lao",
      languageCode: "lo"
    }
  }

  haitian(): SupportedLanguage {
    return {
      nativeName: "Kreyòl Ayisyen",
      name: "Haitian - French Creole",
      languageCode: "ht"
    }
  }

  french(): SupportedLanguage {
    return {
      nativeName: "Français",
      name: "French",
      languageCode: "fr"
    }
  }

  polish(): SupportedLanguage {
    return {
      nativeName: "Polski",
      name: "Polish",
      languageCode: "pl"
    }
  }

  portuguese(): SupportedLanguage {
    return {
      nativeName: "Português",
      name: "Portuguese",
      languageCode: "pt"
    }
  }

  italian(): SupportedLanguage {
    return {
      nativeName: "Italiano",
      name: "Italian",
      languageCode: "it"
    }
  }

  farsi(): SupportedLanguage {
    return {
      nativeName: "فارسی",
      name: "Farsi",
      languageCode: "fa"
    }
  }

  hmong(): SupportedLanguage {
    return {
      nativeName: "Hmoob",
      name: "Hmoob",
      languageCode: "hmn"
    }
  }

  samoan(): SupportedLanguage {
    return {
      nativeName: "Fa’asamoa",
      name: "Samoan",
      languageCode: "sm"
    }
  }

  iloko(): SupportedLanguage {
    return {
      nativeName: "Iloko",
      name: "Iloko",
      languageCode: "ilo"
    }
  }

  thai(): SupportedLanguage {
    return {
      nativeName: "ไทย",
      name: "Thai",
      languageCode: "th"
    }
  }

}
