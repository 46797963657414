import { FooterSettings, SupportedLanguage } from "../../shared/models";
import { ListeningPostFooterContentBase } from "./listening-post-footer-content-base";

export class PremeraBlueCrossMaFooterContent extends ListeningPostFooterContentBase {

  //PBC Medicare Advantage
  private documentLink = "https://www.premera.com/documents/028023.pdf";
  private entityName = "Premera Blue Cross";

  pdfUrl(): string {
    return this.documentLink;
  }

  legalEntityName(): string {
    return this.entityName;
  }

  languageSupport(): SupportedLanguage[] {
    return [
      this.english(),
      this.spanish(),
      this.chinese(),
      this.vietnamese(),
      this.korean(),
      this.russian(),
      this.tagalog(),
      this.ukrainian(),
      this.khmer(),
      this.japanese(),
      this.amharic(),
      this.oromo(),
      this.punjabi(),
      this.german(),
      this.lao(),
      this.arabic()
    ]
  }
}
